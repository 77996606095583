import type { District } from "../../app/district";

import Id11_255899476_0300 from "./qr-codes-new/Id11_255899476_0300.png";
import Id12_123_4434000217_0100 from "./qr-codes-new/Id12_123_4434000217_0100.png";
import Id15_115_8520840237_0100 from "./qr-codes-new/Id15_115_8520840237_0100.png";
import Id16_4327550053_0800 from "./qr-codes-new/Id16_4327550053_0800.png";
import Id14_123_3394480257_0100 from "./qr-codes-new/Id14_123_3394480257_0100.png";

import Id25_20036_1263085389_0800 from "./qr-codes-new/Id25_20036_1263085389_0800.png";
import Id24_1261416359_0800 from "./qr-codes-new/Id24_1261416359_0800.png";
import Id21_6138641369_0800 from "./qr-codes-new/Id21_6138641369_0800.png";
import Id26_123_5314220287_0100 from "./qr-codes-new/Id26_123_5314220287_0100.png";
import Id22_262563989_0300 from "./qr-codes-new/Id22_262563989_0300.png";

import Id33_204457600_0600 from "./qr-codes-new/Id33_204457600_0600.png";
import Id34_35_6888660227_0100 from "./qr-codes-new/Id34_35_6888660227_0100.png";
import Id36_301456968_0300 from "./qr-codes-new/Id36_301456968_0300.png";
import Id37_45454575_2010 from "./qr-codes-new/Id37_45454575_2010.png";
import Id32_2302061393_2010 from "./qr-codes-new/Id32_2302061393_2010.png";
import Id31_269980072_0300 from "./qr-codes-new/Id31_269980072_0300.png";
import Id101_43_1708990227_0100 from "./qr-codes-new/Id101_43_1708990227_0100.png";
import Id42_6114987349_0800 from "./qr-codes-new/Id42_6114987349_0800.png";
import Id43_293272551_0300 from "./qr-codes-new/Id43_293272551_0300.png";
import Id44_296969346_0300 from "./qr-codes-new/Id44_296969346_0300.png";
import Id41_276681775_0300 from "./qr-codes-new/Id41_276681775_0300.png";
import Id51_207602447_0600 from "./qr-codes-new/Id51_207602447_0600.png";
import Id52_299936101_0300 from "./qr-codes-new/Id52_299936101_0300.png";

import Id121_3533910_8030 from "./qr-codes-new/Id121_3533910_8030.png";
import Id122_107_1388920237_0100 from "./qr-codes-new/Id122_107_1388920237_0100.png";

import Id91_2102245016_2010 from "./qr-codes-new/Id91_2102245016_2010.png";
import Id61_123_7845000207_0100 from "./qr-codes-new/Id61_123_7845000207_0100.png";
import Id62_296575726_0300 from "./qr-codes-new/Id62_296575726_0300.png";
import Id72_6003963379_0800 from "./qr-codes-new/Id72_6003963379_0800.png";
import Id71_20036_1765312369_0800 from "./qr-codes-new/Id71_20036_1765312369_0800.png";

import Id85_2100411762_2010 from "./qr-codes-new/Id85_2100411762_2010.png";
import Id84_107_3157480237_0100 from "./qr-codes-new/Id84_107_3157480237_0100.png";
import Id83_2802081518_2010 from "./qr-codes-new/Id83_2802081518_2010.png";
import Id82_287591038_0300 from "./qr-codes-new/Id82_287591038_0300.png";
import Id81_317339651_0300 from "./qr-codes-new/Id81_317339651_0300.png";

import Id111_245192928_0300 from "./qr-codes-new/Id111_245192928_0300.png";
// import Id112_227229248_0300 from "./qr-codes-new/Id112_227229248_0300.png";
export const data: Array<District> = [
  {
    name: "Jizerské hory",
    trailparks: [
      {
        id: 11,
        name: "Jizerská magistrála",
        slug: "jizerskehorymagistrala",
        operator: "Jizerská o.p.s.",
        bankAccountNumber: "255899476/0300",
        variableSymbol: 3100,
        transparentBankAccountUrl: null,
        url: "https://www.jizerskaops.cz/chci-prispet/moznosti-podpory/",
        qrImage: Id11_255899476_0300,
        facebookPageUrl: "https://www.facebook.com/jizerskamagistrala",
        checkedAt: "2.12.2024",
      },
      {
        id: 12,
        name: "Kořenov, Paseky nad Jizerou",
        slug: "korenov",
        operator: "Jizersky pro vás o.p.s.",
        bankAccountNumber: "123-4434000217/0100",
        variableSymbol: null,
        transparentBankAccountUrl: "https://www.kb.cz/cs/transparentni-ucty/123-4434000217",
        url: "http://www.jizerkyprovas.cz/",
        qrImage: Id12_123_4434000217_0100,
        facebookPageUrl: null,
        checkedAt: "2.12.2024",
      },
      {
        id: 15,
        name: "Pěnčínská magistrála - východ",
        slug: "pencinskamagistralavychod",
        operator: "Josef Bouda - Alšovice",
        bankAccountNumber: "115-8520840237/0100",
        variableSymbol: null,
        transparentBankAccountUrl: null,
        url: "https://bezecke-trate-huntirov.webnode.cz/",
        qrImage: Id15_115_8520840237_0100,
        facebookPageUrl: "https://www.facebook.com/pencinskamagistrala",
        checkedAt: "2.12.2024",
      },
      {
        id: 16,
        name: "Pěnčínská magistrála - západ",
        slug: "pencinskamagistralazapad",
        operator: "Pavel Pavlata - Huntířov",
        bankAccountNumber: "4327550053/0800",
        variableSymbol: null,
        transparentBankAccountUrl: null,
        url: "https://bezecke-trate-huntirov.webnode.cz/",
        qrImage: Id16_4327550053_0800,
        facebookPageUrl: "https://www.facebook.com/pencinskamagistrala",
        checkedAt: "2.12.2024",
      },
      {
        id: 14,
        name: "Zásada a okolí",
        slug: "zasada",
        operator: "Město Zásada",
        bankAccountNumber: "123-3394480257/0100",
        variableSymbol: null,
        transparentBankAccountUrl: null,
        url: "https://www.zasada.cz/volny-cas/lyzarske-bezecke-trate",
        qrImage: Id14_123_3394480257_0100,
        facebookPageUrl: "https://www.facebook.com/CrossCountrySkiZasada",
        checkedAt: "2.12.2024",
      },
    ],
  },
  {
    name: "Krkonoše",
    trailparks: [
      {
        id: 25,
        name: "Harrachov",
        slug: "harrachov",
        operator: "Město Harrachov",
        bankAccountNumber: "20036-1263085389/0800",
        variableSymbol: null,
        transparentBankAccountUrl:
          "https://www.csas.cz/cs/transparentni-ucty#/020036-1263085389/Mesto-Harrachov",
        // url: "https://www.harrachovcard.cz/zima/bezecke-lyzovani",
        url: "https://www.harrachovcard.cz/harrachov/verejna-sbirka",
        qrImage: Id25_20036_1263085389_0800,
        facebookPageUrl: null,
        checkedAt: "2.12.2024",
      },
      {
        id: 24,
        name: "Jilemnice",
        slug: "jilemnice",
        operator: "ČKS SKI Jilemnice",
        bankAccountNumber: "1261416359/0800",
        variableSymbol: null,
        transparentBankAccountUrl: null,
        url: "https://www.skijilemnice.cz/web/trate/mapy",
        qrImage: Id24_1261416359_0800,
        facebookPageUrl: null,
        checkedAt: "2.12.2024",
      },
      {
        id: 21,
        name: "Malá Úpa",
        slug: "malaupa",
        operator: "Obec Malá Úpa",
        bankAccountNumber: "6138641369/0800",
        variableSymbol: null,
        transparentBankAccountUrl:
          "https://www.csas.cz/cs/transparentni-ucty#/000000-6138641369/OBEC-MALA-UPA",
        // url: "https://malaupa.cz/aktivity/zima/bezky/",
        url: "https://malaupa.cz/blog/2022/02/07/dobrovolny-prispevek-na-upravu-bezeckych-trati-v-male-upe/",
        qrImage: Id21_6138641369_0800,
        facebookPageUrl: "https://www.facebook.com/malaupa",
        checkedAt: "2.12.2024",
      },
      {
        id: 26,
        name: "Rokytnice nad Jizerou",
        slug: "rokytnicenadjizerou",
        operator: "Město Rokytnice nad Jizerou",
        bankAccountNumber: "123-5314220287/0100",
        variableSymbol: null,
        transparentBankAccountUrl: null,
        url: "https://www.mesto-rokytnice.cz/cs/informacni-centrum/lyze-bezky-snowboard.html",
        qrImage: Id26_123_5314220287_0100,
        facebookPageUrl: null,
        checkedAt: "2.12.2024",
      },
      {
        id: 22,
        name: "Vrchlabí",
        slug: "vrchlabi",
        operator: "Areál Vejspachy",
        bankAccountNumber: "262563989/0300",
        variableSymbol: null,
        transparentBankAccountUrl: null,
        url: "https://www.nabezky.cz/#plan-trati",
        qrImage: Id22_262563989_0300,
        facebookPageUrl: "https://www.facebook.com/nabezkydoVejsplach/",
        checkedAt: "2.12.2024",
      },
      {
        id: 23,
        name: "Benecko",
        slug: "benecko",
        operator: "Sdružení pro rozvoj na Benecku z. s.",
        bankAccountNumber: null,
        variableSymbol: null,
        transparentBankAccountUrl: null,
        url: "https://www.benecko.info/zima/bezecke-lyzovani",
        qrImage: null,
        facebookPageUrl: "https://www.facebook.com/BeneckoKrkonose/",
        checkedAt: "2.12.2024",
      },
    ],
  },
  {
    name: "Krušné hory",
    trailparks: [
      {
        id: 37,
        name: "Adolfov",
        slug: "adolfov",
        operator: "Horský spolek Adolfov, z.s.",
        bankAccountNumber: "45454575/2010",
        variableSymbol: null,
        transparentBankAccountUrl: "https://ib.fio.cz/ib/transparent?a=45454575",
        url: "https://www.hsadolfov.cz/zima/",
        qrImage: Id37_45454575_2010,
        facebookPageUrl: "https://www.facebook.com/hsadolfov",
        checkedAt: "2.12.2024",
      },
      {
        id: 33,
        name: "Alšovka",
        slug: "alsovka",
        operator: "Horal Venkov z.s.",
        bankAccountNumber: "204457600/0600",
        variableSymbol: 2021001,
        transparentBankAccountUrl: null,
        url: "https://alsovka.cz/bezecke-trasy/",
        qrImage: Id33_204457600_0600,
        facebookPageUrl: "https://www.facebook.com/SkiarealAlsovka",
        checkedAt: "2.12.2024",
      },
      {
        id: 36,
        name: "Cínovec - Nové Město",
        slug: "cinovec",
        operator: "MAS Cínovecko, o.p.s.",
        bankAccountNumber: "301456968/0300",
        variableSymbol: null,
        transparentBankAccountUrl: null,
        url: "https://bezky.mascinovecko.cz/",
        qrImage: Id36_301456968_0300,
        facebookPageUrl: "https://www.facebook.com/bezeckestopy",
        checkedAt: "2.12.2024",
      },
      {
        id: 34,
        name: "Dlouhá Louka - Fláje",
        slug: "dlouhalouka",
        operator: "Krušnohorský klub Osek",
        bankAccountNumber: "35-6888660227/0100",
        variableSymbol: null,
        transparentBankAccountUrl: null,
        url: "http://kbstopa.cz/",
        qrImage: Id34_35_6888660227_0100,
        facebookPageUrl: "https://www.facebook.com/krusnohorskabilastopa",
        checkedAt: "2.12.2024",
      },
      {
        id: 32,
        name: "Loučná pod Klínovcem",
        slug: "loucnapodklinovcem",
        operator: "Město Loučná pod Klínovcem",
        bankAccountNumber: "2302061393/2010",
        variableSymbol: 2122010,
        transparentBankAccountUrl: null,
        url: "https://www.loucna.eu/infocentrum/zima/bezecke-lyzovani-1/loucna-pod-klinovcem/",
        qrImage: Id32_2302061393_2010,
        facebookPageUrl: "https://www.facebook.com/mestoloucna",
        checkedAt: "2.12.2024",
      },
      {
        id: 31,
        name: "Západní Krušné hory",
        slug: "krusnehoryzapad",
        operator: "První krušnohorská o.p.s.",
        bankAccountNumber: "269980072/0300",
        variableSymbol: null,
        transparentBankAccountUrl:
          "https://www.csob.cz/portal/firmy/bezne-ucty/transparentni-ucty/ucet?account=269980072",
        // url: "http://www.krusnehoryaktivne.cz/",
        url: "http://www.krusnehoryaktivne.cz/?page_id=11",
        qrImage: Id31_269980072_0300,
        facebookPageUrl: "https://www.facebook.com/KLMzapad",
        checkedAt: "2.12.2024",
      },

      // {
      //   name: "Klíny - střední Krušné hory",
      //   operator: "Sport areál Klíny",
      //   bankAccountNumber: null,
      //   transparentBankAccountUrl: null,
      //   url: "https://kliny.cz/bezecke-trasy/",
      //   qrImage: null,
      //   facebookPageUrl: null,
      //   checkedAt: "4.2.2023",
      // },

      {
        id: 35,
        name: "Děčínský Sněžník",
        slug: "decinskysneznik",
        operator: "HUDYsport a.s.",
        bankAccountNumber: null,
        variableSymbol: null,
        transparentBankAccountUrl: null,
        url: null,
        qrImage: null,
        facebookPageUrl: "https://www.facebook.com/bezkysneznik/",
        checkedAt: "2.12.2024",
      },
    ],
  },
  {
    name: "Lužické hory",
    trailparks: [
      {
        id: 101,
        name: "Polevsko",
        slug: "polevsko",
        operator: "SKI Polevsko - oddíl běžeckého lyžování",
        bankAccountNumber: "43-1708990227/0100",
        variableSymbol: null,
        transparentBankAccountUrl: null,
        url: "https://skipolevsko.estranky.cz/clanky/kontakt.html",
        qrImage: Id101_43_1708990227_0100,
        facebookPageUrl: "https://www.facebook.com/ski.polevsko/",
        checkedAt: "2.12.2024",
      },
    ],
  },
  {
    name: "Orlické hory",
    trailparks: [
      {
        id: 42,
        name: "Buková hora - Suchý vrch",
        slug: "bukovahora",
        operator: "Sdružení obcí Orlicko",
        bankAccountNumber: "6114987349/0800",
        variableSymbol: null,
        transparentBankAccountUrl:
          "https://www.csas.cz/cs/transparentni-ucty#/000000-6114987349/Sdruzeni-obci-Orlicko",
        url: "https://www.orlicko.cz/lyzarske-stopy-orlicka",
        qrImage: Id42_6114987349_0800,
        facebookPageUrl:
          "https://www.facebook.com/Sdru%C5%BEen%C3%AD-obc%C3%AD-Orlicko-1621311478157605",
        checkedAt: "2.12.2024",
      },
      {
        id: 43,
        name: "Deštné v Orlických horách",
        slug: "orlickehorydestne",
        operator: "Deštenský spolek",
        bankAccountNumber: "293272551/0300",
        variableSymbol: null,
        transparentBankAccountUrl:
          "https://www.csob.cz/portal/firmy/bezne-ucty/transparentni-ucty/ucet?account=293272551",
        url: "https://www.destne.info/#/aktuality",
        qrImage: Id43_293272551_0300,
        facebookPageUrl: "",
        checkedAt: "2.12.2024",
      },
      {
        id: 44,
        name: "Orlické Záhoří",
        slug: "orlickezahori",
        operator: "Obec Orlické Záhoří",
        bankAccountNumber: "296969346/0300",
        variableSymbol: null,
        transparentBankAccountUrl:
          "https://www.csob.cz/portal/firmy/bezne-ucty/transparentni-ucty/ucet?account=296969346",
        url: "https://www.orlickezahori.eu",
        qrImage: Id44_296969346_0300,
        facebookPageUrl: "https://www.facebook.com/orlickezahori.eu",
        checkedAt: "2.12.2024",
      },
      {
        id: 41,
        name: "Říčky v Orlických horách",
        slug: "orlickehoryricky",
        operator: "Obec Rokytnice v Orlických horách",
        variableSymbol: 672003429,
        bankAccountNumber: "276681775/0300",
        transparentBankAccountUrl: null,
        url: "https://info.rokytnicevoh.cz/prakticke-informace/turisticke-informace/bezecke-trate-v-orlickych-horach/financni-dary-na-udrzbu-bezeckych-trati-422cs.html",
        qrImage: Id41_276681775_0300,
        facebookPageUrl: "",
        checkedAt: "2.12.2024",
      },
    ],
  },
  {
    name: "Západní Čechy",
    trailparks: [
      {
        id: 121,
        name: "Lipová, Dyleň",
        slug: "dylen",
        operator: "Život na Dyleň, z.s.",
        bankAccountNumber: "3533910/8030",
        variableSymbol: 500,
        transparentBankAccountUrl: null,
        url: "http://zivotnadylen.cz/bezecke%2Dtrate%2Dpod%2Ddyleni/ds-1003",
        qrImage: Id121_3533910_8030,
        facebookPageUrl: "https://www.facebook.com/zivotnadylen",
        checkedAt: "2.12.2024",
      },
      {
        id: 122,
        name: "Mariánské lázně",
        slug: "marianskelazne",
        operator: "Ski Nordic Mariánské Lázně",
        bankAccountNumber: "107-1388920237/0100",
        variableSymbol: null,
        transparentBankAccountUrl: null,
        url: "https://skinordicml.cz/bezecke-trate/",
        qrImage: Id122_107_1388920237_0100,
        facebookPageUrl: "https://www.facebook.com/skinordicml/",
        checkedAt: "2.12.2024",
      },
    ],
  },
  {
    name: "Šumava",
    trailparks: [
      {
        id: 51,
        name: "Šumavská magistrála",
        slug: "sumavamagistrala",
        operator: "Regionální rozvojová agentura Šumava, o.p.s.",
        bankAccountNumber: "207602447/0600",
        variableSymbol: null,
        transparentBankAccountUrl: null,
        url: "https://bilastopa.cz/cs/financovani/",
        qrImage: Id51_207602447_0600,
        facebookPageUrl: "https://www.facebook.com/bilastopasumava",
        checkedAt: "2.12.2024",
      },
      {
        id: 52,
        name: "Pasečná",
        slug: "pasecna",
        operator: "Reuterovy paseky SE",
        bankAccountNumber: "299936101/0300",
        variableSymbol: 202300080,
        transparentBankAccountUrl: null,
        url: "https://bezkypasecna.cz/",
        qrImage: Id52_299936101_0300,
        facebookPageUrl: "https://www.facebook.com/bezky.pasecna",
        checkedAt: "2.12.2024",
      },
    ],
  },

  {
    name: "Novohradské hory",
    trailparks: [
      {
        id: 91,
        name: "Hojná Voda",
        slug: "hojnavoda",
        operator: "Sportklub Hojná Voda, z.s.",
        bankAccountNumber: "2102245016/2010",
        variableSymbol: null,
        transparentBankAccountUrl: null,
        url: "https://www.hojna-voda.eu/index.php/bezky",
        qrImage: Id91_2102245016_2010,
        facebookPageUrl: null,
        checkedAt: "2.12.2024",
      },
    ],
  },
  {
    name: "Jeseníky",
    trailparks: [
      {
        id: 61,
        name: "Jeseníky magistrála",
        slug: "jesenikymagistrala",
        operator: "Sdružení cestovního ruchu Jeseníky",
        bankAccountNumber: "123-7845000207/0100",
        variableSymbol: null,
        transparentBankAccountUrl: "https://www.kb.cz/cs/transparentni-ucty/123-7845000207",
        url: "https://www.jeseniky.cz/cz/turisticke-cile/k2198-lyzarska-bezecka-centra/",
        qrImage: Id61_123_7845000207_0100,
        facebookPageUrl: "https://www.facebook.com/navstivtejeseniky.cz/",
        checkedAt: "2.12.2024",
      },
      {
        id: 62,
        name: "Paprsek",
        slug: "jesenikypaprsek",
        operator: "Hotel Paprsek",
        bankAccountNumber: "296575726/0300",
        variableSymbol: 2413,
        transparentBankAccountUrl: null,
        url: "https://www.horskyarealpaprsek.cz/zimni-sezona/bezecke-trasy/",
        qrImage: Id62_296575726_0300,
        facebookPageUrl: "https://www.facebook.com/skiarealpaprsek",
        checkedAt: "2.12.2024",
      },
    ],
  },
  {
    name: "Beskydy",
    // https://www.beskydy.cz/web/bezky/
    // Vetsina "parku" nema sbirku, cislo uctu, nic.
    trailparks: [
      {
        id: 72,
        name: "Pustevny",
        slug: "pustevny",
        operator: "Běžecký areál Pustevny z.s.",
        bankAccountNumber: "6003963379/0800",
        variableSymbol: null,
        transparentBankAccountUrl: "https://www.csas.cz/cs/transparentni-ucty#/000000-6003963379",
        url: "http://www.ba-pustevny.cz/",
        qrImage: Id72_6003963379_0800,
        facebookPageUrl: "https://www.facebook.com/bapustevny",
        checkedAt: "2.12.2024",
      },
      {
        id: 71,
        name: "Velké Karlovice",
        slug: "velkekarlovice",
        operator: "Obec Velké Karlovice",
        bankAccountNumber: "20036-1765312369/0800",
        variableSymbol: null,
        transparentBankAccountUrl: null,
        url: "https://www.velkekarlovice.cz/rekreace-sport/zimni-sporty/bezecke-trasy-1/",
        qrImage: Id71_20036_1765312369_0800,
        facebookPageUrl: "",
        checkedAt: "2.12.2024",
      },
    ],
  },
  {
    name: "Vysočina",
    trailparks: [
      {
        id: 85,
        name: "Jihlavsko",
        slug: "jihlavsko",
        operator: "Lyžařské Jihlavsko, z.s.",
        bankAccountNumber: "2100411762/2010",
        variableSymbol: null,
        transparentBankAccountUrl: null,
        url: "https://www.lyzarskejihlavsko.cz/",
        qrImage: Id85_2100411762_2010,
        facebookPageUrl: "https://www.facebook.com/lyzarskejihlavsko/",
        checkedAt: "2.12.2024",
      },
      {
        id: 84,
        name: "Novoměstsko",
        slug: "novomestsko",
        operator: "Mikroregion Novoměstsko",
        bankAccountNumber: "107-3157480237/0100",
        variableSymbol: null,
        transparentBankAccountUrl: null,
        url: "https://lyzovani.nmnm.cz/verejna-sbirka-na-udrzbu-trati/",
        qrImage: Id84_107_3157480237_0100,
        facebookPageUrl: null,
        checkedAt: "2.12.2024",
      },
      {
        id: 83,
        name: "Svratka",
        slug: "svratka",
        operator: "Niva Svratky, o.p.s.",
        bankAccountNumber: "2802081518/2010",
        variableSymbol: null,
        transparentBankAccountUrl: "https://ib.fio.cz/ib/transparent?a=2802081518",
        url: null,
        qrImage: Id83_2802081518_2010,
        facebookPageUrl: "https://www.facebook.com/xcskisvratka/",
        checkedAt: "2.12.2024",
      },
      {
        id: 82,
        name: "Velké Dářko",
        slug: "velkedarko",
        operator: "DSO Subregion Velké Dářko",
        bankAccountNumber: "287591038/0300",
        variableSymbol: null,
        transparentBankAccountUrl:
          "https://www.csob.cz/portal/firmy/bezne-ucty/transparentni-ucty/ucet?account=287591038",
        url: "http://www.ski-velkedarko.cz/financovani",
        qrImage: Id82_287591038_0300,
        facebookPageUrl: "https://www.facebook.com/skivelkedarko",
        checkedAt: "2.12.2024",
      },
      {
        id: 81,
        name: "Žďár nad Sázavou",
        slug: "zdarnadsazavou",
        operator: "Sportis Žďár nad Sázavou",
        bankAccountNumber: "317339651/0300",
        variableSymbol: null,
        transparentBankAccountUrl:
          "https://www.csob.cz/portal/firmy/bezne-ucty/transparentni-ucty/ucet?account=317339651",
        url: "https://www.sportispo.cz/page.aspx?IDTema=12",
        qrImage: Id81_317339651_0300,
        facebookPageUrl: "",
        checkedAt: "2.12.2024",
      },
    ],
  },
  {
    name: "Střední Čechy",
    trailparks: [
      {
        id: 111,
        name: "Čertovo břemeno",
        slug: "certovobremeno",
        operator: "Společenstvo obcí Čertovo břemeno",
        bankAccountNumber: "245192928/0300",
        variableSymbol: null,
        transparentBankAccountUrl: null,
        url: "https://www.certovo-bremeno.cz/bezkarske-trasy",
        qrImage: Id111_245192928_0300,
        facebookPageUrl: "https://www.facebook.com/profile.php?id=100063484092919",
        checkedAt: "2.12.2024",
      },
      // {
      //   id: 112,
      //   name: "Polánka",
      //   slug: "polanka",
      //   operator: "Občanské sdružení Polánka",
      //   bankAccountNumber: "227229248/0300",
      //   variableSymbol: null,
      //   transparentBankAccountUrl: null,
      //   url: "https://www.ospolanka.cz/",
      //   qrImage: Id112_227229248_0300,
      //   facebookPageUrl: "https://www.facebook.com/ospolanka.cz",
      //   checkedAt: "3.12.2023",
      // },
    ],
  },
];
